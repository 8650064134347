/*
  0 - 600px:      Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800] is where our normal styles apply
  1800px + :      Big desktop

  $breakpoint arguement choices:
  - phone
  - tab-port
  - tab-land
  - big-desktop

  ORDER: Base + typography > general layout + grid > page layout > components

  1em = 16px
  */
.btn, .btn:link, .btn:visited {
  text-align: left;
  text-decoration: none;
  padding: 0.5rem 2rem;
  display: inline-block;
  border-radius: 0.5rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.7rem;
  font-weight: 600;
  cursor: pointer;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.btn__icon--left {
  margin-right: 1rem;
}
.btn__icon--right {
  margin-left: 1rem;
}
.btn:active, .btn:focus {
  outline: none;
}
.btn--primary {
  background-color: #e5a102;
  color: #fff;
}
.btn--primary:hover {
  background-color: #cc8f02;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.btn--secondary {
  background-color: #94c01f;
  color: #fff;
}
.btn--secondary:hover {
  background-color: #83aa1b;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.btn--tertiary {
  background-color: #fff;
  color: #2a2a29;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  transition: 0.2s ease;
}
.btn--tertiary:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
}
.btn--quaternary {
  background-color: #f15853;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  transition: 0.2s ease;
}
.btn--quaternary:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  background-color: #f26661;
}
.btn--popup--header {
  background-color: #fff;
  color: #2a2a29;
  /*box-shadow: $box-shadow;*/
  transition: 0.2s ease;
  border: none;
  padding: 8px 20px;
}
.btn--popup--header:hover {
  /*box-shadow: $box-shadow-dark;*/
  background-color: #f7f7f7;
}
.btn--default {
  padding: 0;
  background: none;
  border: none;
  font-size: 1.6rem;
  font-weight: 400;
}

.list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}
.list__item:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
@media only screen and (max-width: 48em) {
  .list__item {
    font-size: 1.5rem;
  }
}
.list__box {
  display: flex;
  margin-left: 1rem;
}
.list__btn {
  border: 1px solid rgba(85, 85, 84, 0.2);
  font-size: 2rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  border-radius: 30rem;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  color: #bfbfbe;
  cursor: pointer;
  transition: 0.2s ease;
}
@media only screen and (max-width: 48em) {
  .list__btn {
    font-size: 1.7rem;
    padding: 0.25rem 1.5rem;
  }
}
.list__btn:hover {
  background-color: #009ede;
  color: #fff;
}
.list__btn:not(:last-child) {
  margin-right: 1rem;
}
.list__btn--active {
  background-color: #009ede;
  color: #fff;
}

.nav-btn {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem;
  border-radius: 30rem;
  cursor: pointer;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  position: relative;
  transition: 0.2s ease;
}
.nav-btn:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.nav-btn__icon {
  width: 2rem;
  height: 2rem;
  color: #2a2a29;
}

.nav__logo {
  width: 100%;
  padding: 1rem;
  background-color: #eeeeee;
  color: rgba(42, 42, 41, 0.3);
  text-align: center;
  margin-bottom: 2rem;
}
.nav__logo__svg {
  width: 120px;
  height: 30px;
  margin-top: 7px;
}
.nav__logo select {
  margin: 10px auto;
  margin-top: 20px;
}
.nav__items {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10rem auto 1rem;
}
.nav__items--full {
  width: 100%;
  flex: 1;
  justify-content: end;
  margin-bottom: 0;
}
.nav__items--full .nav__link:not(:last-child) {
  margin-bottom: 2px;
}
.nav__items--full .nav__link {
  background-color: #bfbfbe;
  border-radius: 0;
}
.nav__items--full .nav__link:hover {
  background-color: #b2b2b1;
}
.nav__items--full .nav__link--active {
  background-color: #009ede;
  color: #fff;
}
.nav__items--full .nav__link--active:hover {
  background-color: #009ede;
}
.nav__link {
  background-color: #fff7bf;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1.7rem;
  color: #2a2a29;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease;
}
.nav__link:not(:last-child) {
  margin-bottom: 1rem;
}
.nav__link:hover {
  background-color: #fff18c;
}
.nav__link-icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.nav--left {
  min-width: 50rem;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transform: translateX(-55rem);
  transition: 0.2s ease;
}
@media only screen and (max-width: 48em) {
  .nav--left {
    min-width: 35rem;
  }
}
.nav--right {
  min-width: 40rem;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transform: translateX(55rem);
  transition: 0.2s ease;
}
@media only screen and (max-width: 48em) {
  .nav--right {
    min-width: 35rem;
  }
}

.nav-btn--right .nav-btn__icon {
  height: 2.6rem;
  width: 2.6rem;
}

.nav-btn--right {
  padding: 1.1rem;
}

.nav__close {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem;
  border-radius: 30rem;
  cursor: pointer;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  position: relative;
  transition: 0.2s ease;
}
.nav__close:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.nav__close--icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #2a2a29;
}
.nav__close--left {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}
.nav__close--right {
  position: absolute;
  left: 3rem;
  top: 1rem;
}

.dropdown {
  width: 90%;
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  margin: 0 auto;
}
.dropdown__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #009ede;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.dropdown__header:not(:last-child) {
  margin-bottom: 2rem;
}
.dropdown__icon {
  width: 2rem;
  height: 2rem;
  transform: rotate(270deg);
  transition: 0.2s ease;
}
.dropdown__items {
  display: none;
  margin-bottom: 2rem;
}
.dropdown__item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: none;
  color: #2a2a29;
}
.dropdown__item__title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 270px;
}
.dropdown__item--4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dropdown__item--4 .leerlijn__box {
  display: grid;
  width: 50px;
  grid-template-columns: repeat(3, 1fr);
}
.dropdown__item-icon {
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
.dropdown--secondary .dropdown__header {
  background-color: #023e7c;
}
.dropdown--tertiary .dropdown__header {
  border: 1px solid #009ede;
  background-color: #fff;
  color: #009ede;
}
.dropdown--4 .dropdown__header {
  background-color: #b2e2f5;
  color: #2a2a29;
}

.transform-right {
  transform: translateX(0);
  transition: 0.3 ease;
}

.transform-left {
  transform: translateX(0);
  transition: 0.3 ease;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(42, 42, 41, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
  z-index: 999;
}
.overlay__visible {
  visibility: visible;
  opacity: 1;
}

.nav__message {
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;
  top: -0.5rem;
  right: -0.5rem;
  background-color: #e84e0f;
  border-radius: 30rem;
}

.visible {
  display: block;
}

.animated {
  transform: rotate(0deg);
}

.message {
  margin-left: 1rem;
}

.lesmateriaal_link.active {
  background-color: #fff7bf;
}

.belangrijk {
  font-size: 12px;
  margin-left: 5px;
}

.prettydropdown li.selected {
  background-color: #fff7bf !important;
}

.prettydropdown > ul.active > li.nohover {
  background: inherit;
  color: #000 !important;
}

.prettydropdown > ul.active > li:not(.label):hover, .prettydropdown > ul.active > li.hover:not(.label), .prettydropdown > ul.active > li:first-child:hover:after {
  background: #fff7bf;
  color: #000;
}

.prettydropdown > ul.active > li.selected.nohover {
  background: #fff7bf !important;
  color: #000;
}

.prettydropdown > ul.active > li.highlight.nohover, .prettydropdown li.highlight, .prettydropdown li.highlight.hover, .prettydropdown li.highlight.nohover {
  background-color: #eeeeee !important;
}

.prettydropdown .bold {
  font-weight: 600;
}

.fa-module {
  color: #009ede;
  margin-right: 10px;
}

.window {
  position: absolute;
  /*left: 50%;*/
  left: 10%;
  top: 100px;
  transform-origin: top center;
  transform: translate(-10%, 0);
  z-index: 9000;
  background-color: #fff;
  width: 800px;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: none;
}

#window5 {
  /* Antwoorden popup */
  width: 500px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[id^=closeButton] {
  color: #ffffff;
  cursor: pointer;
  position: relative;
  font-size: 24px;
}

.window__title {
  font-size: 2rem;
  /*text-transform: uppercase;*/
  padding: 6px 30px;
  z-index: 10;
  background-color: #009ede;
  color: #fff;
  border-radius: 1.5rem 1.5rem 0 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  touch-action: none;
  font-weight: 600;
}
.window__title--yellow {
  background-color: #ffe94c;
  color: #2a2a29;
}
.window__title--yellow b {
  color: #2a2a29;
}
.window__title--orange {
  background-color: #e5a102;
  color: #fff;
}

.window--subhead {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.window--subhead--gray {
  background-color: #eeeeee;
}
.window--subhead--yellow {
  background-color: #fff7bf;
}
.window--subhead--orange {
  background-color: #faeed1;
}
.window--subhead--center {
  justify-content: center;
}

.window__footer {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}
.window__footer__button {
  cursor: pointer;
  border: 1px solid #e9e9e9;
  padding: 4px 10px;
  font-weight: 600;
  border-radius: 5px;
}
.window__footer__button--white {
  background-color: #fff;
}
.window__footer__button:hover {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
}
.window__footer--gray {
  background-color: #eeeeee;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.window__footer__button i {
  color: #009ede;
}

.windowActive {
  z-index: 10000;
}

.mainWindow {
  padding-right: 20px;
  border: 20px solid white;
  max-height: 70vh;
  min-height: 300px;
  overflow: auto;
  border-radius: 0 0 1.5rem 1.5rem;
}

.small {
  width: 600px;
}

.large {
  width: 1000px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.btns-dictionary {
  display: flex;
}

.btn-dictionary {
  border-radius: 4px;
  border: 1px solid #555554;
  padding: 0px 7px;
  cursor: pointer;
}

.btn-dictionary:hover {
  background-color: #ffe94c;
  border-color: #ffe94c;
}

.dictionary-items table tr:nth-child(odd) {
  background-color: #eeeeee;
}

.dictionary-items table td {
  padding: 8px;
  font-size: 1.6rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header__content {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  justify-content: flex-start;
  margin-left: 3rem;
}

.profile-id {
  position: absolute;
  right: 3rem;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  color: #bfbfbe;
  font-weight: 300;
}
@media only screen and (max-width: 75em) {
  .profile-id {
    right: 3rem;
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 48em) {
  .profile-id {
    position: relative;
    right: 0;
  }
}

.main {
  width: 100%;
  margin: 2rem auto;
  max-width: 1800px;
  position: relative;
}

.panel-active {
  transform: scale(1) !important;
  transition: 0.2s ease;
  z-index: 1;
}

.panel-wrapper {
  height: 80vh;
  /*overflow-y: scroll;*/
  overflow-y: hidden;
  overflow-x: hidden;
}

.context-panel .panel-wrapper {
  overflow-y: scroll;
}

.context-panel table {
  table-layout: auto;
  width: auto;
}

.exercise-panel__bottom {
  overflow-y: scroll;
  overflow-x: hidden;
}

.panel-wrapper.noscroll {
  overflow-y: hidden;
}

.context-panel {
  position: absolute;
  left: 0px;
  width: 54%;
  min-width: 800px;
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 3rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  transition: 0.2s ease;
  transform-origin: center left;
}
@media only screen and (max-width: 87.5em) {
  .context-panel {
    padding: 3rem;
    grid-column: 1/span 5;
  }
}
@media only screen and (max-width: 75em) {
  .context-panel {
    padding: 3rem;
  }
}
@media only screen and (max-width: 48em) {
  .context-panel {
    padding: 1.5rem;
  }
}
.context-panel__img {
  width: 100%;
  margin-bottom: 2rem;
}

.exercise-panel {
  position: absolute;
  right: 0px;
  width: 54%;
  min-width: 800px;
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 3rem;
  transform-origin: center right;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  transition: 0.2s ease;
}
@media only screen and (max-width: 87.5em) {
  .exercise-panel {
    padding: 3rem;
    grid-column: 2/span 5;
  }
}
@media only screen and (max-width: 75em) {
  .exercise-panel {
    padding: 3rem;
  }
}
@media only screen and (max-width: 48em) {
  .exercise-panel {
    padding: 1.5rem;
  }
}
.exercise-panel__top {
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #bfbfbe;
  display: flex;
  justify-content: space-between;
}
.exercise-panel__middle {
  padding: 1.5rem 0;
  border-bottom: 1px solid #bfbfbe;
}
.exercise-panel__bottom {
  position: relative;
  padding: 2rem 0 0;
  padding-right: 30px;
}
.exercise-panel__bottom__disabler {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.001;
  z-index: 10000;
  display: none;
}
.exercise-panel__title {
  background-color: #023e7c;
  color: #fff;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0.9rem 2.8rem 0.9rem 6.5rem;
  border-radius: 30rem;
  width: max-content;
  position: relative;
  margin-left: 5px;
}
.exercise-panel__title--sub {
  display: inline-block;
  background-color: #009ede;
  border-radius: 30rem;
  padding: 0.9rem 1.9rem 0.9rem;
  position: absolute;
  left: -3px;
  top: 0;
}
.exercise-panel .panel-wrapper {
  padding: 0 3rem 0 0;
}
.exercise-panel__feedback {
  padding: 2rem 0 0 0;
}

.feedback-button {
  display: none;
  background-color: #fff7bf;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  display: inline-block;
  cursor: pointer;
}

.feedback-container {
  display: none;
  position: relative;
  background-color: #fff7bf;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}

.exercise-panel__feedback .orange {
  color: #e5a102;
  margin-right: 4px;
}

.close-feedback {
  background-color: #fff;
  color: #e5a102;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}

.leerlijn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leerlijn__box {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.leerlijn__box .btn--primary {
  margin-right: 1rem;
}
@media only screen and (max-width: 56.25em) {
  .leerlijn__box:last-child {
    display: flex;
    flex-direction: column;
  }
  .leerlijn__box:last-child .btn--primary {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
  .leerlijn__box:last-child .btn--primary,
.leerlijn__box:last-child .btn--secondary {
    min-width: 15rem;
    padding: 0.25rem;
    font-size: 1.6rem;
  }
  .leerlijn__box:last-child .btn__icon--right,
.leerlijn__box:last-child .btn__icon--left {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.leerlijn__icon {
  width: 42px;
  height: 24px;
  margin-right: 12px;
}
.leerlijn__num {
  font-size: 3rem;
  font-weight: 700;
  margin-right: 2rem;
  color: #023e7c;
}
.leerlijn__level {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 30rem;
}
.leerlijn__level:not(:last-child) {
  margin-right: 0.5rem;
}
.leerlijn__level--1 {
  background-color: #fff;
  border: 1px solid rgba(42, 42, 41, 0.3);
}
.leerlijn__level--2 {
  background-color: #e5a102;
}
.leerlijn__level--3 {
  background-color: #009ede;
}
.leerlijn__level--hidden {
  visibility: hidden;
}

.context-item {
  padding-right: 20px;
  margin-bottom: 30px;
}

.context-item video {
  width: 100%;
  height: auto;
  border: 1px solid #bfbfbe;
  border-radius: 1.3rem;
}

.context-text {
  padding: 20px;
  padding-right: 40px;
}

.context-header-image {
  border-radius: 1.3rem;
  max-width: 100%;
}

.context-item.soort-1, .context-item.soort-2, .context-item.soort-3, .context-item.soort-4, .context-item.soort-5, .context-item.soort-6 {
  margin-right: 20px;
  border-radius: 1.3rem;
}

.context-item.soort-1, .context-item.soort-3, .context-item.soort-6 {
  background-color: #81d0f5;
}

.context-item.soort-2, .context-item.soort-4, .context-item.soort-5 {
  background-color: #fff381;
}

.context-item.soort-2 .marker, .context-item.soort-4 .marker, .context-item.soort-5 .marker {
  background-color: #ffff00;
}

.context-item.soort-1 .heading-secondary, .context-item.soort-2 .heading-secondary, .context-item.soort-3 .heading-secondary, .context-item.soort-4 .heading-secondary, .context-item.soort-5 .heading-secondary, .context-item.soort-6 .heading-secondary {
  font-family: "Zilla Slab", serif;
  font-weight: 700;
}

table.exercises {
  margin-top: 10px;
}

table.exercises td {
  height: 50px;
  line-height: 35px;
}

td.vraagnummer {
  width: 30px;
}

/* STELLINGEN */
.stelling {
  display: inline-block;
  min-width: 20px;
  cursor: pointer;
  border-radius: 2rem;
  padding: 2px 4px;
}
.stelling.highlight {
  background-color: #e9e9e9;
}
.stelling.right {
  float: right;
}
.stelling span {
  border: 1px solid #bfbfbe;
  padding: 4px 15px;
  border-radius: 1.5rem;
  font-weight: 600;
  background-color: #fff;
}
.stelling span:hover {
  background-color: #009ede;
  border: 1px solid #009ede;
  color: #fff;
}
.stelling span.active {
  background-color: #009ede;
  border: 1px solid #009ede;
  color: #fff;
}
.stelling span.goed {
  background-color: #94c01f;
  border-color: #94c01f;
}
.stelling span.fout {
  background-color: #f15853;
  border-color: #f15853;
}

table.exercises input {
  width: auto;
  padding: 5px;
  padding-bottom: 0px;
}

table.exercises input.highlight {
  background-color: #e9e9e9;
}

table.exercises input.goed {
  color: #94c01f;
  font-weight: 600;
}

table.exercises input.fout {
  color: #f15853;
  font-weight: 600;
}

table.exercises input.klein {
  width: 100px;
}

table.exercises input.middel {
  width: 200px;
}

table.exercises input.groot {
  width: 200px;
}

table.exercises .textarea {
  background-image: url(/img/bg-dots.png);
  color: #023e7c;
  padding: 0px 10px;
  line-height: 45px;
  font-weight: 600;
}

table.exercises .textarea.highlight {
  background-color: #e9e9e9;
}

table.exercises .textarea.goed {
  color: #94c01f;
  font-weight: 600;
}

table.exercises .textarea.fout {
  color: #f15853;
  font-weight: 600;
}

input.groot, input.klein, input.middel, input.veld-100 {
  border: 0;
  text-align: center;
  border-bottom: 2px dotted #73c9ed;
  padding: 0px 5px;
  border-radius: 0;
  color: #023e7c;
  font-size: 1.7rem;
  font-weight: 600;
}

input.groot {
  text-align: left;
}

table.exercises input.veld-100 {
  text-align: left;
  width: 95%;
}

.combinatie-holder {
  display: flex;
  flex-flow: row wrap;
  gap: 10px 20px;
}

.combinatie {
  display: flex;
  align-items: center;
  width: 300px;
  height: 70px;
  flex-shrink: 0;
  line-height: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 0.6rem;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  z-index: 100;
}

.combinatie .letter {
  background-color: #009ede;
  color: #fff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  flex-shrink: 0;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.combinatie.movable {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

.combinatie.goed {
  background-color: #94c01f;
  border-color: #94c01f;
}

.combinatie.goed .letter, .combinatie.fout .letter {
  background-color: transparent;
}

.combinatie.fout {
  background-color: #f15853;
  border-color: #f15853;
}

.combinatie-antwoord.highlight {
  border: 1px solid #bfbfbe;
  background-color: #e9e9e9;
}

.drop-zone {
  background-color: #eeeeee;
  padding: 20px;
  margin-top: 20px;
}

.drop-zone table {
  width: auto;
  margin: auto;
}

.combinatie-antwoord {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  border: 1px solid #e9e9e9;
  line-height: 20px;
  border-radius: 0.6rem;
  text-align: left;
  padding: 5px 20px;
  height: 70px;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
}

.combinatie-antwoord .nummer {
  color: #009ede;
  padding-right: 10px;
}

.drop-container {
  width: 300px;
  border: 1px solid #e9e9e9;
  border-radius: 0.6rem;
  text-align: left;
  font-weight: 600;
  height: 70px;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
}

.combinatie-container {
  background-color: #eeeeee;
  border-radius: 0.6rem;
  width: 300px;
  height: 70px;
}

.combinatie-tr td {
  padding-right: 2px;
}

.combinatie-tr {
  border: 2px dashed transparent;
}

.combinatie-tr.highlight {
  border-color: #e5a102;
}

table.schema {
  width: 100%;
  table-layout: auto;
}

.schema__titel {
  font-weight: bold;
  color: #009ede;
}

td.meerkeuze__keuze {
  width: 40px;
}

table.meerkeuze td {
  height: auto;
}

.meerkeuze_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.meerkeuze_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark, .checkmark-abc {
  position: absolute;
  top: 5px;
  left: 0;
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 23px;
  font-size: 1.8rem;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 50%;
}

.meerkeuze_container:hover input ~ .checkmark, .meerkeuze_container:hover input ~ .checkmark-abc {
  border-color: #b2e2f5;
  background-color: #b2e2f5;
}

.meerkeuze_container input:checked ~ .checkmark, .meerkeuze_container input:checked ~ .checkmark-abc {
  background-color: #009ede;
  border: 1px solid #009ede;
  color: #fff;
}

.meerkeuze_container input:checked ~ .checkmark.goed {
  background-color: #94c01f;
}

.meerkeuze_container input:checked ~ .checkmark.fout {
  background-color: #f15853;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.meerkeuze_container input:checked ~ .checkmark:after {
  display: block;
}

.meerkeuze_container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 8px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.woordzoeker-woorden {
  display: flex;
  flex-wrap: wrap;
}

.woordzoeker-woord {
  flex-grow: 1;
  width: 33%;
  line-height: 30px;
  color: #2a2a29;
}

.woordzoeker-woord.found {
  color: #00b394;
  text-decoration: line-through;
}

.woordzoeker-vragen {
  margin-bottom: 10px;
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: auto auto;
}

.woordzoeker-vragen div {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 10px;
}

.woordzoeker-holder {
  background-color: #eeeeee;
  padding: 20px;
  margin-top: 20px;
}

table.woordzoeker {
  width: auto;
  margin: auto;
}

table.woordzoeker td {
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #00b394;
  background-color: #fff;
  border-radius: 0.5rem;
}

table.woordzoeker td.active {
  background-color: #00b394;
  color: #fff;
}

table.woordzoeker td.correct {
  background-color: #00b394;
  color: #fff;
}

table.woordzoeker td.correct.goed {
  background-color: #94c01f;
}

table.woordzoeker td, table.woordzoeker td div {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

table.woordzoeker td div {
  display: inline;
  padding: 2px 8px;
  cursor: pointer;
}

table.antwoorden {
  table-layout: auto;
}

.td-vraagnummer {
  width: 30px !important;
}

table.kruiswoordpuzzel {
  border-collapse: separate !important;
  table-layout: auto;
  width: auto;
  border-spacing: 2px;
  margin-bottom: 40px;
}

td.kwp-cell {
  width: 30px;
  height: 30px !important;
  border: 1px solid #bfbfbe;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

td.kwp-cell.black {
  border: none;
}

.kwp-cell-nummer {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 12px;
  line-height: 10px;
}

.kwp-input {
  border: none;
  width: 30px !important;
  height: 30px;
  text-align: center;
  background-color: transparent;
}

table.kwp-table {
  table-layout: auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

table.kwp-table td {
  height: auto;
}

.kwp-cell.goed, .kwp-cell.goed input {
  background-color: #94c01f;
  color: white;
}

.kwp-cell.fout, .kwp-cell.fout input {
  background-color: #f15853;
  color: white;
}

.woord-email {
  background-color: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  margin: 10px;
  margin-bottom: 40px;
  overflow: hidden;
}

.woord-email-header {
  background-color: #023e7c;
  border-top-left-radius: 1.5 rem;
  border-top-right-radius: 1.5 rem;
  height: 36px;
  text-align: right;
  color: #fff;
  padding-right: 10px;
}

.woord-email-header i {
  margin-right: 10px;
  font-size: 16px;
}

.woord-email-content {
  padding: 3rem;
}

.woord-email-footer {
  padding: 20px;
}

.woord-email-footer .btn {
  margin-left: auto;
}

.woord-email-footer .btn i {
  margin-left: 10px;
}

.woord-email-footer .btn--secondary:hover, .woord-email-footer .btn--secondary {
  background-color: #009ede;
  font-size: 16px;
  padding: 0.2rem 2rem;
}

.begrippennet {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.begrippennet td {
  padding: 10px 40px;
}

.input_begrippennet {
  width: 150px !important;
  padding: 10px !important;
  width: 150px !important;
  border-color: #dddddd;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}

.ballon_line {
  border: 1px dotted;
  border-radius: 90px;
  color: #2a2a29;
}

#web_midden {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-color: #bfbfbe;
}

#web_midden, .input_begrippennet {
  text-align: center;
}

#begrippennet_container {
  position: relative;
}

connection {
  z-index: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  position: fixed;
  overflow: hidden;
}

html {
  font-size: 62.5%;
  /*scroll-behavior: smooth;*/
  height: 100%;
}

body {
  box-sizing: border-box;
  background-color: #eeeeee;
  overflow: hidden;
  height: 100%;
  margin: 0px;
}

ul {
  padding-left: 20px;
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  width: 95%;
  margin: auto;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2a2a29;
}

body::-webkit-scrollbar-thumb {
  background-color: #009ede;
  outline: none;
}

div::-webkit-scrollbar {
  width: 1rem;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #009ede;
  outline: none;
  border-radius: 5px;
}

::selection {
  background-color: #b2e2f5;
  color: #2a2a29;
}

.option--bold {
  font-weight: bold;
}

.scroller {
  height: 100%;
  overflow-y: auto;
}

#berichten, #bericht, #bericht-formulier, #meldpunt-formulier {
  width: 740px;
  table-layout: fixed;
}

.bericht--titel {
  width: 550px;
}

.bericht-label {
  width: 200px;
}

#bericht {
  display: none;
}

#bericht-formulier-inhoud, #meldpunt-formulier-inhoud {
  height: 130px;
}

.bericht-row td {
  border-top: 1px solid #dddddd;
  padding: 5px 0px;
}

.show-bericht, .delete-bericht {
  cursor: pointer;
}

table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
}

thead {
  font-weight: 600;
}

td.wrap {
  white-space: normal;
}

td, th {
  /*white-space: nowrap;*/
  vertical-align: top;
}

th {
  text-align: left;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.swal2-container {
  z-index: 20000 !important;
}

.swal2-html-container {
  font-size: 1.5em !important;
}

.swal2-styled {
  font-size: 1.4rem !important;
}

.bold {
  font-weight: 600;
}

.hidden {
  display: none;
}

.mr-5 {
  margin-right: 5px;
}

.disabled {
  opacity: 0.3;
}

input, textarea {
  border: 1px solid #e9e9e9;
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 10px;
  font-size: 1.6rem;
  font-family: inherit;
}

textarea {
  resize: none;
}

*:focus {
  outline: none;
}

.invisible {
  visibility: hidden;
}

.grammatica-items .collapsible, .pronunciation-items .collapsible, .theorie-items .collapsible {
  background-color: #fff;
  /* color: white; */
  cursor: pointer;
  font-weight: 600;
  padding: 18px;
  width: 100%;
  border: none;
  border-top: 1px solid #bfbfbe;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.grammatica-items .collapsible:first-child, .pronunciation-items .collapsible:first-child, .theorie-items .collapsible:first-child {
  border-top: none;
}

.grammatica-items .content, .pronunciation-items .content, .theorie-items .content {
  padding: 20px;
  padding-top: 0px;
  display: none;
  overflow: hidden;
}

.lds-ring {
  margin: auto;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #009ede;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #009ede transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-content {
  margin-top: 200px;
}

img {
  /*max-width: 100%;*/
}

.image-style-side {
  float: right;
  max-width: 400px;
  margin-left: 40px;
}

p {
  margin: 1rem 0;
}

.exercises p {
  margin: 0;
}

*[contenteditable] {
  display: inline-block;
  width: 100%;
}

.donut {
  --v:calc( ((18/5) * var(--p) - 90)*1deg);
  width: 60px;
  height: 60px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  font-size: 1.3rem;
  margin-left: 20px;
  border-radius: 50%;
  padding: 6px;
  background: linear-gradient(#fff, #fff) content-box, linear-gradient(var(--v), #eeeeee 50%, transparent 0) 0/min(100%, (50 - var(--p)) * 100%), linear-gradient(var(--v), transparent 50%, var(--c) 0) 0/min(100%, (var(--p) - 50) * 100%), linear-gradient(to right, #eeeeee 50%, var(--c) 0);
}

.voortgang {
  width: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.voortgang .checkbox {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.voortgang .percentage {
  font-size: 1.4rem;
  display: inline-block;
}

.erk {
  color: #2a2a29;
  max-width: 485px;
  padding: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
}

.erk__item {
  display: flex;
}

.erk__titel {
  font-weight: bold;
}

.erk__button, .erk__exercise__button {
  color: #009ede;
  border: 1px solid #dddddd;
  padding: 2px 10px;
  display: inline-block;
  border-radius: 0.7rem;
  cursor: pointer;
}

.erk__titel {
  font-weight: bold;
}

.erk__exercsie__titel {
  flex: 1;
  margin-left: 20px;
}

.erk--item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

/** NAKIJKEN **/
.nakijken-feedback {
  display: none;
  position: relative;
  background-color: #fff7bf;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}

.nakijken-resultaat {
  font-size: 1.6rem;
  background-color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  display: none;
}

.nakijken-resultaat i {
  color: #f79d5d;
}

.nakijken-oefening {
  font-weight: 600;
}

.nakijken-antwoorden {
  font-size: 1.6rem;
}

.nakijken-antwoorden td {
  border-bottom: 1px solid #bfbfbe;
  vertical-align: top;
  padding: 5px 0px;
}

.nakijken-antwoorden label {
  margin-left: 6px;
}

.nakijken-zelfnakijken {
  font-size: 1.6rem;
  padding: 10px;
}

tr.antwoord-woord:hover {
  background-color: #e9e9e9;
}

.zelf {
  background-color: #efefef;
}

input[type=radio].goed, input[type=radio].fout {
  cursor: pointer;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  display: none;
}

input[type=radio].goed ~ label:before, input[type=radio].fout ~ label:before {
  font-family: FontAwesome;
  display: inline-block;
  content: "\f1db";
  letter-spacing: 10px;
  font-size: 1.2em;
  color: #dddddd;
  width: 1.4em;
}

input[type=radio].goed:checked ~ label:before, input[type=radio].fout:checked ~ label:before {
  font-size: 1.2em;
  letter-spacing: 5px;
}

input[type=radio].goed:checked ~ label:before {
  content: "\f058";
  color: #94c01f;
}

input[type=radio].fout:checked ~ label:before {
  content: "\f057";
  color: #f15853;
}

.antwoord-combinatie .nummer {
  display: none;
}

/* WOORDENTRAINER */
.woordentrainer-uitleg {
  width: 60%;
  text-align: center;
  margin: auto;
}

.woordentrainer-taal, .woordentrainer-vertaal {
  text-align: center;
  color: #009ede;
  font-weight: 600;
  padding: 20px;
}

.woordentrainer-woord {
  background-color: #fff7bf;
  border-radius: 2rem;
  padding: 10px;
  width: 50%;
  margin: auto;
  text-align: center;
  font-weight: 600;
}

.woordentrainer-vertaling-knop, .woordentrainer-opnieuw-knop {
  border-radius: 2rem;
  background-color: #009ede;
  color: #fff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 210px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.woordentrainer-vertaling {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.woordentrainer-vertaling-invulveld {
  text-align: center;
  display: block;
  border-radius: 2rem;
}

.input-icon {
  position: absolute;
  left: 20px;
  color: #fff;
  top: calc(50% - 0.5em);
}

.input-icon.goed {
  display: none;
}

.input-icon.fout {
  display: none;
}

.woordentrainer-vertaling-wrapper input.goed {
  background-color: #94c01f;
  color: #fff;
}

.woordentrainer-vertaling-wrapper input.fout {
  background-color: #f15853;
  color: #fff;
}

.woordentrainer-vertaling-wrapper input {
  padding-left: 17px;
}

.woordentrainer-vertaling-wrapper {
  position: relative;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.5rem;
  color: #2a2a29;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.heading-primary {
  font-size: 3rem;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  color: #555554;
  line-height: 1.1;
}
@media only screen and (max-width: 75em) {
  .heading-primary {
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 48em) {
  .heading-primary {
    font-size: 2rem;
  }
}

.heading-secondary {
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 48em) {
  .heading-secondary {
    font-size: 2.8rem;
  }
}

.heading-tertiary {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  font-weight: 400;
}
@media only screen and (max-width: 48em) {
  .heading-tertiary {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.paragraph {
  font-size: 1.7rem;
  color: #2a2a29;
}
@media only screen and (max-width: 25.714em) {
  .paragraph {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}
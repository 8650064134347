.btn {
  &,
  &:link,
  &:visited {
      text-align: left;
    text-decoration: none;
    padding: 0.5rem 2rem;
    display: inline-block;
    border-radius: 0.5rem;
    transition: all 0.2s;
    position: relative;
    font-size: $default-font-size;
    font-weight: 600;
    cursor: pointer;

    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;

    &--left {
      margin-right: 1rem;
    }

    &--right {
      margin-left: 1rem;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &--primary {
    background-color: $color-orange;
    color: $color-white;

    &:hover {
      background-color: darken($color-orange, 5%);
      box-shadow: $box-shadow-dark;
    }
  }

  &--secondary {
    background-color: $color-green;
    color: $color-white;

    &:hover {
      background-color: darken($color-green, 5%);
      box-shadow: $box-shadow-dark;
    }
  }

  &--tertiary {
    background-color: $color-white;
    color: $color-grey-dark-2;
    box-shadow: $box-shadow;
    transition: 0.2s ease;

    &:hover {
      box-shadow: $box-shadow-dark;
      background-color: darken($color-white, 3%);
    }
  }
    &--quaternary {
        background-color: $color-red;
        color: $color-white;
        box-shadow: $box-shadow;
        transition: 0.2s ease;

        &:hover {
            box-shadow: $box-shadow-dark;
            background-color: lighten($color-red, 3%);
        }
    }

    &--popup--header {
        background-color: $color-white;
        color: $color-grey-dark-2;
        /*box-shadow: $box-shadow;*/
        transition: 0.2s ease;
        border: none;
        padding: 8px 20px;

        &:hover {
            /*box-shadow: $box-shadow-dark;*/
            background-color: darken($color-white, 3%);
        }
    }

  &--default {
    padding: 0;
    background: none;
    border: none;
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.list {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $color-grey-light-1;
    }

    @include respond(phone-big) {
      font-size: 1.5rem;
    }
  }

  &__box {
    display: flex;
    margin-left: 1rem;
  }

  &__btn {
    border: 1px solid rgba($color-grey-dark-1, 0.2);
    font-size: 2rem;
    font-family: $font-family;
    font-weight: 700;
    border-radius: 30rem;
    padding: 0.5rem 1.5rem;
    background-color: $color-white;
    box-shadow: $box-shadow;
    color: $color-grey-light-2;
    cursor: pointer;
    transition: 0.2s ease;

    @include respond(phone-big) {
      font-size: $default-font-size;
      padding: 0.25rem 1.5rem;
    }

    &:hover {
      background-color: $color-blue-1;
      color: $color-white;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &--active {
      background-color: $color-blue-1;
      color: $color-white;
    }
  }
}

body {
  font-family: $font-family;
  font-weight: 400;
  font-size: $default-font-size;
  line-height: 2.5rem;
  color: $color-grey-dark-2;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.heading-primary {
  font-size: 3rem;
  font-family: $font-display;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  color: $color-grey-dark-1;
  line-height: 1.1;

  @include respond(tab-port-big) {
    font-size: 2.4rem;
  }

  @include respond(phone-big) {
    font-size: 2rem;
  }
}

.heading-secondary {
  font-size: 30px;
  margin-top: 0px;
    margin-bottom:10px;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  z-index: 1;

  @include respond(phone-big) {
    font-size: 2.8rem;
  }
}

.heading-tertiary {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  font-weight: 400;

  @include respond(phone-big) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.paragraph {
  font-size: $default-font-size;
  color: $color-grey-dark-2;

  @include respond(phone-small) {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

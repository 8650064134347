*,
*::after,
*::before {
  //margin: 0;
  //padding: 0;
  box-sizing: inherit;

    //-webkit-user-select: none;  /* Chrome all / Safari all */
    //-moz-user-select: none;     /* Firefox all */
    //-ms-user-select: none;      /* IE 10+ */
    //user-select: none;          /* Likely future */

}
html,
body {
    width:100%;
    position: fixed;
    overflow: hidden;
}
html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  /*scroll-behavior: smooth;*/
    height:100%;
}

body {
  box-sizing: border-box;
  background-color: $color-grey-light-1;
  overflow: hidden;
  height:100%;
    margin:0px;
}
ul {
    padding-left: 20px;
}

.wrapper {
    position:relative;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    width: 95%;
    margin: auto;
    height:100%;
}

//Scrollbar (body & div-overflow);
body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $color-grey-dark-2;
}

body::-webkit-scrollbar-thumb {
  background-color: $color-blue-1;
  outline: none;
}

div::-webkit-scrollbar {
    width: 1rem;
}

div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color-white;
    border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
    background-color: $color-blue-1;
    outline: none;
    border-radius: 5px;
}


//Selection
::selection {
  background-color: $color-blue-2;
  color: $color-grey-dark-2;
}

//Select option
.option--bold {
    font-weight:bold;
}

//SCrollbar
.scroller {
    height:100%;
    overflow-y:auto;
}

//Berichten
#berichten, #bericht, #bericht-formulier, #meldpunt-formulier {
    width:740px;
    table-layout: fixed;
}
.bericht--titel {
    width:550px;
}
.bericht-label {
    width:200px;
}
#bericht {
    display:none;
}
#bericht-formulier-inhoud, #meldpunt-formulier-inhoud {
    height:130px;
}

.bericht-row td {
    border-top:1px solid $color-grey-light-3;
    padding: 5px 0px;
}
.show-bericht, .delete-bericht {
    cursor:pointer;
}

//Table
table {
    width:100%;
    table-layout: fixed;
    border-spacing: 0px;
}
thead {
    font-weight:600;
}

td.wrap {
    white-space: normal;
}

td, th {
    /*white-space: nowrap;*/
    vertical-align:top;
}

th {
    text-align:left;
}
.ellipsis {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.text-center {
    text-align:center;
}

//Alerts-notifications
.swal2-container {
    z-index: 20000 !important;

}
.swal2-html-container {
    font-size: 1.5em !important;
}

.swal2-styled {
    font-size:1.4rem !important;
}

//Global
.bold {
    font-weight:600;
}
.hidden {
    display:none;
}
.mr-5 {
    margin-right:5px;
}

.disabled {
    opacity: .3;
}

//Form
input, textarea {
    border:1px solid #e9e9e9;
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding:10px 10px;
    font-size:1.6rem;
    font-family:inherit
}
textarea {
    resize: none;
}
*:focus {
    outline: none;
}

.invisible {
    visibility: hidden;
}

//Grammatica & Pronuciation popup
.grammatica-items .collapsible, .pronunciation-items .collapsible, .theorie-items .collapsible {
    background-color: $color-white;
    /* color: white; */
    cursor: pointer;
    font-weight: 600;
    padding: 18px;
    width: 100%;
    border:none;
    border-top:1px solid $color-grey-light-2;
    text-align: left;
    outline: none;
    font-size: 15px;
}
.grammatica-items .collapsible:first-child, .pronunciation-items .collapsible:first-child, .theorie-items .collapsible:first-child { border-top: none; }

.grammatica-items .content, .pronunciation-items .content, .theorie-items .content {
    padding: 20px;
    padding-top:0px;
    display: none;
    overflow: hidden;
}


//Loading
.lds-ring {
    margin:auto;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $color-blue-1;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-blue-1 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-content {
    margin-top:200px;
}

img {
    /*max-width: 100%;*/
}

.image-style-side {
    float: right;
    max-width:400px;
    margin-left:40px;
}

p {
    margin: 1rem 0;
}

.exercises p {
    margin:0;
}

*[contenteditable]{display: inline-block; width:100%}


.donut {
    --v:calc( ((18/5) * var(--p) - 90)*1deg);
    width: 60px;
    height: 60px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    font-size: 1.3rem;
    margin-left: 20px;
    border-radius:50%;
    padding:6px;
    background:
        linear-gradient(#fff,#fff) content-box,
        linear-gradient(var(--v), #eeeeee     50%,transparent 0) 0/min(100%,(50 - var(--p))*100%),
        linear-gradient(var(--v), transparent 50%,var(--c)        0) 0/min(100%,(var(--p) - 50)*100%),
        linear-gradient(to right, #eeeeee 50%,var(--c) 0);
}


.voortgang {
    width:70px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}
.voortgang .checkbox {
    width:20px;
    height:20px;
    display:inline-block;
    margin-right:10px;
}
.voortgang .percentage {
    font-size:1.4rem;
    display:inline-block;
}

.erk {
    color:$color-black-1;
    max-width:485px;
    padding: 10px;
    padding-bottom:20px;
    border-bottom: 1px solid $color-grey-light-3;
}

.erk__item {
    display:flex;
}

.erk__titel {
    font-weight:bold;
}

.erk__button, .erk__exercise__button {
    color:$color-blue-1;
    border: 1px solid $color-grey-light-3;
    padding: 2px 10px;
    display: inline-block;
    border-radius: 0.7rem;
    cursor:pointer;
}
.erk__titel {
    font-weight:bold;
}
.erk__exercsie__titel {
    flex:1;
    margin-left:20px;
}

.erk--item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight:600;
    padding: 15px;
    border-bottom: 1px solid #eee;
}


/** NAKIJKEN **/
.nakijken-feedback {
    display: none;
    position: relative;
    background-color: #fff7bf;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
}
.nakijken-resultaat {
    font-size:1.6rem;
    background-color: #fff;
    padding: 4px 15px;
    border-radius: 5px;
    display:none;
}
.nakijken-resultaat i {
    color:#f79d5d;
}
.nakijken-oefening {
    font-weight:600;
}
.nakijken-antwoorden {
    font-size:1.6rem;
}

.nakijken-antwoorden td {
    border-bottom:1px solid $color-grey-light-2;
    vertical-align: top;
    padding:5px 0px;
}

.nakijken-antwoorden label {
    margin-left: 6px;
}

.nakijken-zelfnakijken {
    font-size: 1.6rem;
    padding: 10px;
}

tr.antwoord-woord:hover {
    background-color: $color-grey-light-4;
}

.zelf {
    background-color: #efefef;
}

input[type=radio].goed, input[type=radio].fout {
    cursor:pointer;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    overflow: hidden;
    padding: 0;
    display:none;
}

input[type=radio].goed ~ label:before, input[type=radio].fout ~ label:before {
    font-family: FontAwesome;
    display: inline-block;
    content: "\f1db";
    letter-spacing: 10px;
    font-size: 1.2em;
    color: $color-grey-light-3;
    width: 1.4em;
}
input[type=radio].goed:checked ~ label:before, input[type=radio].fout:checked ~ label:before {
    font-size: 1.2em;
    letter-spacing: 5px;
}

input[type=radio].goed:checked ~ label:before {
    content: "\f058";
    color: $color-green;
}
input[type=radio].fout:checked ~ label:before {
    content: "\f057";
    color: $color-red;
}

.antwoord-combinatie .nummer {
    display:none;
}


/* WOORDENTRAINER */

.woordentrainer-uitleg {
    width: 60%;
    text-align: center;
    margin:auto;
}

.woordentrainer-taal, .woordentrainer-vertaal {
    text-align:center;
    color:$color-blue-1;
    font-weight:600;
    padding:20px;
}

.woordentrainer-woord {
    background-color:$color-yellow-2;
    border-radius:2rem;
    padding: 10px;
    width: 50%;
    margin:auto;
    text-align: center;
    font-weight:600;
}

.woordentrainer-vertaling-knop, .woordentrainer-opnieuw-knop {
    border-radius:2rem;
    background-color:$color-blue-1;
    color:$color-white;
    padding:10px;
    text-align:center;
    cursor:pointer;
    width:210px;
    margin:auto;
    position:absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.woordentrainer-vertaling {
    position:absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align:center;
}

.woordentrainer-vertaling-invulveld {
    text-align: center;
    display: block;
    border-radius: 2rem;
}

.input-icon {
    position: absolute;
    left: 20px;
    color:$color-white;
    top: calc(50% - 0.5em);
}
.input-icon.goed {
    display:none;
}
.input-icon.fout {
    display:none;
}
.woordentrainer-vertaling-wrapper input.goed {
    background-color:$color-green;
    color:$color-white;
}
.woordentrainer-vertaling-wrapper input.fout {
    background-color:$color-red;
    color:$color-white;
}
.woordentrainer-vertaling-wrapper input {
    padding-left: 17px;
}
.woordentrainer-vertaling-wrapper {
    position: relative;
    width: 50%;
    margin-right: auto;
    margin-left: auto;

}

.nav-btn {
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem;
  border-radius: 30rem;
  cursor: pointer;
  box-shadow: $box-shadow;
  position: relative;
  transition: 0.2s ease;

  &:hover {
    box-shadow: $box-shadow-dark;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    color: $color-grey-dark-2;
  }
}

.nav {
  &__logo {
    width: 100%;
    padding: 1rem;
    background-color: $color-grey-light-1;
    color: rgba($color-grey-dark-2, 0.3);
    text-align: center;
    margin-bottom: 2rem;

    &__svg {
        width:120px;
        height:30px;
        margin-top: 7px;
    }
    select {
        margin: 10px auto;
        margin-top:20px;
    }
  }


    &__items {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 10rem auto 1rem;

    &--full {
      width: 100%;
      flex: 1;
      justify-content: end;
      margin-bottom: 0;
    }
  }

  &__items--full &__link:not(:last-child) {
    margin-bottom: 2px;
  }

  &__items--full &__link {
    background-color: $color-grey-light-2;
    border-radius: 0;

    &:hover {
      background-color: darken($color-grey-light-2, 5%);
    }
  }

  &__items--full &__link--active {
    background-color: $color-blue-1;
    color: $color-white;

    &:hover {
      background-color: $color-blue-1;
    }
  }

  &__link {
    background-color: $color-accent;
    border-radius: 0.5rem;
      font-weight: 600;
      font-size: 1.7rem;
    color: $color-grey-dark-2;
      padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.2s ease;


    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:hover {
      background-color: darken($color-accent, 10%);
    }
  }

  &__link-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  &--left {
    min-width: 50rem;
    background-color: $color-white;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    box-shadow: $box-shadow-dark;
    display: flex;
    flex-direction: column;
    transform: translateX(-55rem);
    transition: 0.2s ease;

    @include respond(phone-big) {
      min-width: 35rem;
    }
  }

  &--right {
    min-width: 40rem;
    background-color: $color-white;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;
    box-shadow: $box-shadow-dark;
    display: flex;
    flex-direction: column;
    transform: translateX(55rem);
    transition: 0.2s ease;

    @include respond(phone-big) {
      min-width: 35rem;
    }
  }
}

.nav-btn--right .nav-btn__icon {
    height:2.6rem;
    width:2.6rem;
}
.nav-btn--right {
    padding:1.1rem
}

.nav__close {
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.3rem;
    border-radius: 30rem;
    cursor: pointer;
    box-shadow: $box-shadow;
    position: relative;
    transition: 0.2s ease;

  &:hover {
    box-shadow: $box-shadow-dark;
  }

  &--icon {
      width: 1.5rem;
      height: 1.5rem;
      color: $color-grey-dark-2;
  }

  &--left {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }

  &--right {
    position: absolute;
    left: 3rem;
    top: 1rem;
  }
}

//List Dropdown
.dropdown {
  width: 90%;
  font-weight: 600;
  color: $color-white;
    font-size:1.6rem;
  border-radius: 0.5rem;
  margin: 0 auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-blue-1;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    transform: rotate(270deg);
    transition: 0.2s ease;
  }

  &__items {
    display: none;
    margin-bottom: 2rem;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: none;
    color: $color-grey-dark-2;

      &__title {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor:pointer;
          max-width: 270px;
      }

    &--4 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .leerlijn__box {
        display: grid;
          width: 50px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__item-icon {
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &--secondary {
    .dropdown__header {
      background-color: $color-blue-3;
    }
  }

  &--tertiary {
    .dropdown__header {
      border: 1px solid $color-blue-1;
      background-color: $color-white;
      color: $color-blue-1;
    }
  }

  &--4 {
    .dropdown__header {
      background-color: #b2e2f5;
      color: $color-grey-dark-2;
    }
  }
}

.transform-right {
  transform: translateX(0);
  transition: 0.3 ease;
}

.transform-left {
  transform: translateX(0);
  transition: 0.3 ease;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color-grey-dark-2, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
  z-index: 999;

  &__visible {
    visibility: visible;
    opacity: 1;
  }
}

.nav__message {
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: $color-white;
  top: -0.5rem;
  right: -0.5rem;
  background-color: #e84e0f;
  border-radius: 30rem;
}

.visible {
  display: block;
}

.animated {
  transform: rotate(0deg);
}

.message {
  margin-left: 1rem;
}

.lesmateriaal_link.active {
    background-color:$color-yellow-2;
}

.belangrijk {
    font-size: 12px;
    margin-left: 5px;
}

.prettydropdown li.selected {
    background-color:#fff7bf !important;
}
.prettydropdown > ul.active > li.nohover {
    background: inherit;
    color: #000 !important;
}
.prettydropdown > ul.active > li:not(.label):hover, .prettydropdown > ul.active > li.hover:not(.label), .prettydropdown > ul.active > li:first-child:hover:after {
    background: #fff7bf;
    color: #000;
}
.prettydropdown > ul.active > li.selected.nohover {
    background: #fff7bf !important;
    color: #000;
}

.prettydropdown > ul.active > li.highlight.nohover, .prettydropdown li.highlight, .prettydropdown li.highlight.hover, .prettydropdown li.highlight.nohover {
    background-color:$color-grey-light-1 !important;
}

.prettydropdown .bold {
    font-weight:600;
}

.fa-module {
    color:$color-blue-1;
    margin-right:10px;
}

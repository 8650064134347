.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    position: relative;
  flex: 1;
  justify-content: flex-start;
  margin-left: 3rem;
  }
}

.profile-id {
  position: absolute;
    right: 3rem;
    font-size: 2.4rem;
  text-transform: uppercase;
  font-family: $font-display;
  color: $color-grey-light-2;

  font-weight: 300;

  @include respond(tab-port-big) {
    right: 3rem;
    font-size: 2.4rem;
  }

  @include respond(phone-big) {
    position: relative;
    right: 0;
  }
}

//Colors
$color-blue-1: #009ede;
$color-blue-2: #b2e2f5;
$color-blue-3: #023e7c;

$color-white: #fff;

$color-grey-light-1: #eeeeee;
$color-grey-light-2: #bfbfbe;
$color-grey-light-3: #dddddd;
$color-grey-light-4: #e9e9e9;

$color-grey-dark-1: #555554;
$color-grey-dark-2: #2a2a29;

$color-accent: #fff7bf;

$color-orange: #e5a102;
$color-orange-1: #faeed1;

$color-green: #94c01f;
$color-red: #f15853;

$color-yellow-1: #ffe94c;
$color-yellow-2: #fff7bf;

$color-black-1: #2a2a29;

$color-exercise-input: #023e7c;




//Shadow
$box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
$box-shadow-dark: 0 1rem 2rem rgba(0, 0, 0, 0.1);

//Font
$font-display: 'Barlow Condensed', sans-serif;
$font-family: 'Source Sans Pro', sans-serif;
$default-font-size: 1.7rem;

.window {
	position: absolute;
    /*left: 50%;*/
    left:10%;
    top: 100px;
    transform-origin: top center;
    transform: translate(-10%, 0);
	z-index: 9000;
	background-color: #fff;
	width: 800px;
    border-radius: 1.5rem;
    box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
	display: none;
}
#window5 { /* Antwoorden popup */
    width: 500px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

[id^="closeButton"] {
    color: #ffffff;
    cursor: pointer;
    position: relative;
    font-size: 24px;
}

.window__title {
    font-size:2rem;
    /*text-transform: uppercase;*/
    padding: 6px 30px;
	z-index: 10;
	background-color: $color-blue-1;
	color: $color-white;
	border-radius: 1.5rem 1.5rem 0 0;
	justify-content: space-between;
    align-items: center;
	display: flex;
	touch-action: none;
    font-weight:600;

    &--yellow  {
        background-color: $color-yellow-1;
        color:$color-black-1;
    }
    &--yellow b {
        color:$color-black-1;
    }

    &--orange  {
        background-color: $color-orange;
        color: $color-white;
    }
}


.window--subhead {
    padding:15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--gray {
        background-color:$color-grey-light-1;
    }
    &--yellow {
        background-color:$color-yellow-2;
    }
    &--orange {
        background-color:$color-orange-1;
    }

    &--center {
        justify-content: center;
    }
}

.window__footer {
    padding:10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:1.5rem;

    &__button {
        cursor:pointer;
        border:1px solid $color-grey-light-4;
        padding:4px 10px;
        font-weight:600;
        border-radius:5px;

        &--white {
            background-color:#fff;
        }
    }

    &__button:hover {
        box-shadow: 0 1rem 1rem rgb(0 0 0 / 5%);
    }

    &--gray {
        background-color:$color-grey-light-1;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }


    &__button i {
        color:$color-blue-1;
    }
}


.windowActive {
	z-index: 10000;
}

.mainWindow {
	padding-right: 20px;
    border: 20px solid white;
    max-height: 70vh;
    min-height:300px;
    overflow: auto;
    border-radius: 0 0 1.5rem 1.5rem;
}



.small {
	width: 600px;
}

.large {
	width: 1000px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.btns-dictionary {
    display: flex;
}
.btn-dictionary {
    border-radius: 4px;
    border: 1px solid $color-grey-dark-1;
    padding: 0px 7px;
    cursor:pointer;
}

.btn-dictionary:hover {
    background-color:$color-yellow-1;
    border-color: $color-yellow-1;
}

.dictionary-items table tr:nth-child(odd){
    background-color: $color-grey-light-1;
}

.dictionary-items table td {
    padding: 8px;
    font-size: 1.6rem;
}

